import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.h1`
  &.site-title {
    color: ${props => props.theme.textTitleFontColor};
    text-align: center;
  }
`

const SiteTitle = ({ children }) => {
  return <StyledWrapper className="site-title">{children}</StyledWrapper>
}

export default SiteTitle
